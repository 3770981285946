import { getCookie } from "./cookie";
import { getLocalStorageData } from "./localstorage";
import { brands } from "../data/car_brands";
import { IBrand, IYear, IDivideStyle } from "../types/index";

export const desiredKeys: string[] = ["id", "name"];

export const generateImagePath = (name: string): string => {
  const userid = getLocalStorageData("id") || getCookie("id");

  return `${process.env.REACT_APP_BASE_URL}/get/${userid}/${name}`;
};

export const getFormatDate = (time: number = 0): string => {
  const minTime = new Date(1970, 0, 2).getTime();

  if (!time || isNaN(time) || time < 0 || time < minTime) return "не известно";

  const options: Intl.DateTimeFormatOptions = {
    day: "numeric",
    month: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: false,
  };

  return new Intl.DateTimeFormat("ru-RU", options).format(time);
};

export const getFormatCost = (cost: number | string): string => {
  const options = {
    style: "currency",
    currency: "RUB",
    minimumFractionDigits: 0,
  };

  const zero = "0 ₽";

  const number = Number(String(cost).replace(/[.]/g, ""));

  if (isNaN(number)) return zero;

  if (number <= 0) return zero;

  return String(new Intl.NumberFormat("ru-RU", options).format(number));
};

export const getFormatMileage = (mileage: number | string): string => {
  const options = {
    useGrouping: true,
  };

  const zero = "0 km";

  const number = Number(mileage);

  if (isNaN(number)) return zero;

  if (number <= 0) return zero;

  const result = String(
    new Intl.NumberFormat("ru-RU", options).format(number),
  ).replace(/\u00a0/g, " ");

  return `${result} km`;
};

export const maskCost = (value: string | number): string => {
  const zero = "";

  if (!value) return zero;

  const number = Number(String(value).replace(/ /g, ""));

  if (isNaN(number) || number <= 0) return zero;

  const onlyNumbers = String(value).replace(/\D/g, "");

  if (!onlyNumbers) return zero;

  return onlyNumbers.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
};

export const maskPhoneNumber = (v: string): string => {
  if (!v) return "";

  const mask = "8 (###) ###-##-##";
  let formatted = "";
  let valueIndex = 0;
  const cleanedValue = v.replace(/\D+/g, "");

  for (let i = 0; i < mask.length; i++) {
    if (mask[i] === "#") {
      if (valueIndex < cleanedValue.length) {
        formatted += cleanedValue[valueIndex];
        valueIndex++;
      } else {
        break;
      }
    } else {
      if (valueIndex < cleanedValue.length || mask[i] !== v[i]) {
        formatted += mask[i];
      } else {
        break;
      }
      if (
        valueIndex < cleanedValue.length &&
        mask[i] === cleanedValue[valueIndex]
      ) {
        valueIndex++;
      }
    }
  }
  return formatted;
};

export const maskCarNumber = (v: string): string => {
  if (!v) return "";

  const m = "A###AA###";
  let res = "";
  let digitIdx = 0;
  let letterIdx = 0;
  const cleanDigits = v.replace(/[^\d]+/g, "");
  const cleanLetters = v.replace(/[^а-яА-ЯёЁ]+/g, "");

  for (let i = 0; i < m.length; i++) {
    if (m[i] === "#") {
      if (digitIdx < cleanDigits.length) {
        res += cleanDigits[digitIdx];
        digitIdx++;
      } else {
        break;
      }
    } else if (m[i] === "A") {
      if (letterIdx < cleanLetters.length) {
        res += cleanLetters[letterIdx];
        letterIdx++;
      } else {
        break;
      }
    } else {
      res += m[i];
    }
  }
  return res;
};

export const getBrands = (test: boolean = false): IBrand[] => {
  if (test) {
    return brands.slice(0, 4).map((el) => {
      return {
        label: String(el.name),
        value: String(el.name),
      };
    });
  }

  return brands.map((el) => {
    return {
      label: String(el.name),
      value: String(el.name),
    };
  });
};

export const getYears = (test: boolean = false): IYear[] => {
  const result = [];
  const now = new Date();
  const currentYear = test ? 2024 : now.getFullYear();
  const startYear = test ? 2020 : 1950;

  for (let year = startYear; year <= currentYear; year++) {
    result.push({
      label: String(year),
      value: String(year),
    });
  }

  return result.reverse();
};

export const sanitize = (val: string): string => {
  return val.replace(/<[^>]*>/gi, "").toLowerCase();
};

export const getDivideStyle = (): IDivideStyle => ({
  margin: 0,
  fontSize: ".75rem",
});

export const delSpaces = (row: string | number): string => {
  return sanitize(String(row)).trim();
};

export const downloadImage = async (name: string): Promise<void> => {
  const img = await fetch(generateImagePath(name));

  const blob = await img.blob();

  const url = window.URL.createObjectURL(blob);

  const link = document.createElement("a");

  link.href = url;
  link.download = name;

  document.body.appendChild(link);

  link.click();

  document.body.removeChild(link);

  window.URL.revokeObjectURL(url);
};
