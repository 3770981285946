import React, { useState, useEffect } from "react";
import { IFile } from "../../types/base_object";
import { generateImagePath } from "../../helpers";
import { LayoutFormat } from "../LayoutFormat";
import { Button } from "antd";
import { MyModal } from "../MyModal";
import "./index.css";

// Компонент для отображения PDF-файла
export const PdfViewer = ({ file }: { file: IFile }) => {
  // Состояние видимости модального окна
  const [show, changeShow] = useState<boolean>(false);
  const [isPath, setPath] = useState<string>("");
  const [isAlt, setAlt] = useState<string>("");
  const [isThumb, setThumb] = useState<string>("");
  // Действия при инициализации компонента
  useEffect(() => {
    // Генерируем и добавляем путь к файлу
    setPath(generateImagePath(file?.name?.new));
    // Добавляем альтернативное имя файла
    setAlt(file?.name?.old);
    // Генерируем и добавляем путь к превью-изображению
    setThumb(generateImagePath(file?.thumb));
  }, []);
  // Возвращаем шаблон
  return (
    <>
      <LayoutFormat label="pdf">
        <img
          className="video-viewer__img"
          src={isThumb}
          alt={isAlt}
          onClick={() => changeShow(true)}
        />
      </LayoutFormat>

      {show && (
        <MyModal onCancel={() => changeShow(false)}>
          <embed
            src={isPath}
            type="application/pdf"
            style={{ width: "100%", height: "100%" }}
          />

          <Button
            onClick={() => window.open(isPath, "_blank")}
            style={{ width: "100%" }}
          >
            Открыть
          </Button>
        </MyModal>
      )}
    </>
  );
};
