import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import Layout from "./components/Layout";
import CarsPage from "./pages/cars";
import CarPage from "./pages/cars/car";
import ObjectsPage from "./pages/objects";
import ObjectPage from "./pages/objects/object";
import ErrorPage from "./pages/ErrorPage";
import LoginPage from "./pages/login";
import Middleware from "./components/Middleware";
//@ts-ignore
import { useWindowSize } from "./store/useWindowSize.js";

export const App = () => {
  useWindowSize();
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route element={<Middleware />}>
            <Route index element={<Navigate to="cars" replace />} />

            <Route path="cars">
              <Route index element={<CarsPage />} />

              <Route path=":id" element={<CarPage />} />
            </Route>

            <Route path="objects">
              <Route index element={<ObjectsPage />} />

              <Route path=":id" element={<ObjectPage />} />
            </Route>
          </Route>

          <Route path="*" element={<ErrorPage />} />
        </Route>

        <Route path="/login" element={<LoginPage />} />
      </Routes>
    </BrowserRouter>
  );
};
