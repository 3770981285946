import React, { useState } from "react";
import { Modal } from "antd";
// Компонент для отображения PDF-файла
export const MyModal = ({ children, onCancel }: any) => {
  // Состояние видимости модального окна
  const [show, changeShow] = useState<boolean>(true);
  // Возвращаем шаблон
  return (
    <Modal
      centered
      width={"max-content"}
      open={show}
      onCancel={() => onCancel()}
    >
      <div
        style={{
          width: "80vw",
          height: "80vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: ".25rem",
        }}
      >
        {children}
      </div>
    </Modal>
  );
};
