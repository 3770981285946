import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { Flex } from "antd";
import { IState } from "../../types/store";
import Navbar from "../Navbar";
import Logo from "../Logo";
import "./index.css"; // Импортируем стили
// Компонент шапки приложения
export default function HeaderMain() {
  // Получаем ширину экрана для desktop
  const { desktop } = useSelector((state: IState) => state.breakpoints);
  // Текущая ширина экрана клиента
  const { width } = useSelector((state: IState) => state.windowSize);
  // Кэшированное значение ширины экрана клиента
  const isDesktop = useMemo(() => width >= desktop, [width, desktop]);
  // Возвращаем шаблон
  return (
    <div className="header__wrap">
      <header className="header" id="isHeader">
        <div className="container">
          <Flex
            gap="small"
            justify={isDesktop ? "space-between" : ""}
            align={!isDesktop ? "center" : ""}
          >
            <Logo />

            <Navbar />
          </Flex>
        </div>
      </header>
    </div>
  );
}
