import React, { useState } from "react";
import { NavLink, Navigate } from "react-router-dom";
import { Button } from "antd";
import { list } from "../../data/navbar";
import { LogoutOutlined } from "@ant-design/icons";
import {
  clearLocalstorage,
  getStatusLocalStorageData,
} from "../../helpers/localstorage";
import { clearCookies, getStatusCookie } from "../../helpers/cookie";
import DownloadBtn from "../Controls/DownloadBtn";
import "./index.css";
// Компонент navbar в шапке сайта
export default function Navbar() {
  // Состояние авторизованности
  const [isAuth, setIsAuth] = useState(true);
  // Флаг наличия нужных данных в localstorage
  const statusLocalstorage = getStatusLocalStorageData();
  // Флаг наличия нужных данных в cookie
  const statusCookie = getStatusCookie();
  // Флаг активности кнопок навигации
  const isType = (isActive: boolean) => (isActive ? "default" : "primary");
  // Функция для выхода из системы
  const logout = () => {
    clearLocalstorage();
    clearCookies();
    setIsAuth(false);
  };
  // Возвращаем шаблон компонента
  return isAuth && (statusLocalstorage || statusCookie) ? (
    <nav className="navbar">
      <ul className="navbar__list">
        {list.map((obj) => {
          return (
            <li key={obj.path}>
              <NavLink to={obj.path}>
                {({ isActive }) => (
                  <Button size="small" type={isType(isActive)}>
                    {obj.name}
                  </Button>
                )}
              </NavLink>
            </li>
          );
        })}
      </ul>

      <div className="navbar__logout">
        <DownloadBtn title="скачать все данные" />

        <Button size="small" type="primary" danger onClick={logout}>
          <LogoutOutlined />
        </Button>
      </div>
    </nav>
  ) : (
    <Navigate to="/login" />
  );
}
