// Импорт React и useState
import React from "react";
// Импортируем карточку для видеофайла
import { VideoPlayer } from "../VideoPlayer";
// Импортируем карточку для PDF файла
import { PdfViewer } from "../PdfViewer";
// Импортируем карточку для изображения
import { ImageViewer } from "../ImageViewer";
// Импорт вспомогательных функций
import { generateImagePath } from "../../helpers";
// Импортируем интерфейс файла
import { IFile } from "../../types/base_object";
// Компонент кнопок [скачать, удалить]
export default function RenderItem({
  file,
  single,
}: {
  file: IFile;
  single: Boolean;
}) {
  // Функция для определения, какой элемент рендерить
  const renderFile = (file: any) => {
    const filePath = generateImagePath(
      file?.thumb ? file?.thumb : file?.name?.new,
    );
    if (file?.name?.new?.endsWith(".webp")) {
      return (
        <ImageViewer src={filePath} alt={file?.name?.old} single={single} />
      );
    } else if (file?.name?.new?.endsWith(".pdf")) {
      return <PdfViewer file={file} />;
    } else if (file?.name?.new?.endsWith(".mp4")) {
      return <VideoPlayer file={file} />;
    }
    return null;
  };
  // Возвращаем шаблон компонента
  return (
    <div
      style={{
        borderRadius: "4px",
        overflow: "hidden",
        height: "148px",
        width: "100%",
      }}
    >
      {renderFile(file)}
    </div>
  );
}
