import React, { useState, useEffect, useCallback } from "react";

import { useParams } from "react-router-dom";

import { Flex, Divider, Empty } from "antd";

import Loader from "../../components/Loader";
import InsidePageHeader from "../../components/Header/InsidePage";
import TheRow from "../../components/Row";

import { myFetch } from "../../helpers/my_fetch";
import { useBreadcrumbsContext } from "../../components/Breadcrumbs/context";
import { InsidePageBtns } from "../../components/Controls/InsidePage/buttons";
import { showErrorNotify } from "../../helpers/notify";
import { IRes } from "../../types/index";

export default function CarPage() {
  const [car, setCar]: any = useState(null);
  const [loader, setLoader] = useState(true);

  const { addBreadcrumb, removeBreadcrumb } = useBreadcrumbsContext();

  const { id } = useParams();

  const getData = useCallback(async () => {
    try {
      const { data, error }: IRes = await myFetch(`car/${id}`, {}, "GET");

      if (error) return showErrorNotify(error);

      setCar(data);

      return data;
    } catch (e) {
    } finally {
      setLoader(false);
    }
  }, [id]);

  useEffect(() => {
    let isMounted = true;

    const setCrumb = async () => {
      const res: any = await getData();

      if (isMounted && res) {
        const crumb = {
          name: res?.brand || "",
          path: "/cars",
        };

        addBreadcrumb(crumb);
      }
    };

    setCrumb();

    return () => {
      if (isMounted) {
        removeBreadcrumb("/cars");
      }

      isMounted = false;
    };
  }, [addBreadcrumb, removeBreadcrumb, getData]);

  if (loader) return <Loader />;

  return (
    <Flex vertical gap="small">
      <InsidePageBtns
        getData={getData}
        id={String(id)}
        isData={car}
        isPage="car"
      />

      <InsidePageHeader getData={getData} isData={car} id={String(id)} />

      <Divider style={{ margin: 0 }}>детали</Divider>

      {!car?.details?.length && <Empty />}

      {!!car?.details?.length &&
        car?.details?.map((detail: any, i: number) => (
          <TheRow
            getData={getData}
            id={String(id)}
            isData={detail}
            isPage="car"
            index={i}
            length={car?.details?.length}
            key={detail?.id}
          />
        ))}
    </Flex>
  );
}
