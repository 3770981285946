import { getFormatDate, getFormatCost, getFormatMileage } from "./index";

const spanForAll = 3;

const getArrOptions = (obj: any) => {
  const options = obj?.options || [];
  if (!options) return [];
  const keys: string[] = Object.keys(options);
  if (!keys) return [];
  return keys?.map((key: string) => ({
    key: `option-${key}`,
    label: options[key].name,
    span: spanForAll,
    children: getFormatCost(obj.options[key].cost),
  }));
};

export const getArrForDescriptionsCarPage = (data: any) => {
  if (!Object.keys(data)) return [];

  return [
    {
      key: "1",
      label: "производитель",
      span: spanForAll,
      children: data.brand,
    },
    {
      key: "2",
      label: "модель",
      span: spanForAll,
      children: data.model,
    },
    {
      key: "3",
      label: "год",
      span: spanForAll,
      children: data.year,
    },
    {
      key: "4",
      label: "номер",
      span: spanForAll,
      children: data.number,
    },
    {
      key: "5",
      label: "все затраты",
      span: spanForAll,
      children: getFormatCost(data.sum),
    },
    {
      key: "6",
      label: "дата создания",
      span: spanForAll,
      children: getFormatDate(data.date.start),
    },
  ];
};

export const getArrForDescriptionsCarPageRow = (data: any) => {
  if (!Object.keys(data)) return [];

  let arr = [];

  if (!!data.description.length) {
    arr = [
      {
        key: "1",
        label: "описание",
        span: spanForAll,
        children: data.description,
      },
      {
        key: "2",
        label: "затраты",
        span: spanForAll,
        children: getFormatCost(data.sum),
      },
      {
        key: "3",
        label: "пробег",
        span: spanForAll,
        children: getFormatMileage(data.milleage),
      },
      {
        key: "4",
        label: "дата создания",
        span: spanForAll,
        children: getFormatDate(data.date.start),
      },
    ];
  } else {
    arr = [
      {
        key: "1",
        label: "затраты",
        span: spanForAll,
        children: getFormatCost(data.sum),
      },
      {
        key: "2",
        label: "пробег",
        span: spanForAll,
        children: getFormatMileage(data.milleage),
      },
      {
        key: "3",
        label: "дата создания",
        span: spanForAll,
        children: getFormatDate(data.date.start),
      },
    ];
  }

  return [...arr, ...getArrOptions(data)];
};

export const getArrForDescriptionsObjectPage = (data: any) => {
  if (!Object.keys(data)) return [];

  const arr = [
    {
      key: "1",
      label: "название",
      span: spanForAll,
      children: data.name,
    },
    {
      key: "2",
      label: "все затраты",
      span: spanForAll,
      children: getFormatCost(data.sum),
    },
  ];

  if (!!data.description?.length) {
    arr.push({
      key: "3",
      label: "описание",
      span: spanForAll,
      children: data.description,
    });
    arr.push({
      key: "4",
      label: "дата создания",
      span: spanForAll,
      children: getFormatDate(data.date.start),
    });
  } else {
    arr.push({
      key: "3",
      label: "дата создания",
      span: spanForAll,
      children: getFormatDate(data.date.start),
    });
  }

  return arr;
};

export const getArrForDescriptionsObjectPageRow = (data: any) => {
  if (!Object.keys(data)) return [];

  const arr = [
    {
      key: "1",
      label: "затраты",
      span: spanForAll,
      children: getFormatCost(data.sum),
    },
  ];

  if (!!data.description) {
    arr.push({
      key: "2",
      label: "описание",
      span: spanForAll,
      children: data.description,
    });
    arr.push({
      key: "3",
      label: "дата создания",
      span: spanForAll,
      children: getFormatDate(data.date.start),
    });
  } else {
    arr.push({
      key: "2",
      label: "дата создания",
      span: spanForAll,
      children: getFormatDate(data.date.start),
    });
  }

  return [...arr, ...getArrOptions(data)];
};
