import { list as listNavbar } from "./navbar";

export const list = [
  ...listNavbar,
  {
    name: "пользователи",
    path: "/users",
  },
  {
    name: "данные",
    path: "/data",
  },
];
