import React, { useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Flex, Button, Modal, Popconfirm } from "antd";
import { PlusCircleFilled, DeleteFilled, EditFilled } from "@ant-design/icons";
import CreateCarDetail from "../../Forms/Car/CreateCarDetail";
import CreateObjectDetail from "../../Forms/Object/CreateObjectDetail";
import EditCar from "../../Forms/Car/EditCar";
import EditObject from "../../Forms/Object/EditObject";
import { myFetch } from "../../../helpers/my_fetch";
import { showSuccessNotify, showErrorNotify } from "../../../helpers/notify";
import { IControls } from "../../../types/props";

export const InsidePageBtns = React.memo(
  ({ getData, isData, id, isPage }: IControls) => {
    const [loader, setLoader] = useState(false);
    const [isModal, setIsModal] = useState(false);
    const [isModalEdit, setIsModalEdit] = useState(false);
    const [modalKey, setModalKey] = useState(Date.now());

    const navigate = useNavigate();

    const isCarPage = useMemo(() => isPage === "car", [isPage]);

    const del = async () => {
      setLoader(true);

      try {
        const { data, error } = await myFetch(`${isPage}/${id}`, {}, "DELETE");

        if (error) return showErrorNotify(error);

        showSuccessNotify(data);

        navigate(`/${isPage}s`);
      } catch (e) {
      } finally {
        setLoader(false);
      }
    };

    const toClose = async () => {
      setIsModal(false);
      setIsModalEdit(false);
      setModalKey(Date.now());
    };

    return (
      <Flex vertical gap="small">
        <Flex gap="small" justify="end">
          <Button
            size="small"
            type="primary"
            onClick={() => setIsModalEdit(true)}
            icon={<EditFilled />}
          />

          <Button
            size="small"
            type="primary"
            onClick={() => setIsModal(true)}
            style={{ backgroundColor: "var(--green)" }}
            icon={<PlusCircleFilled />}
          />

          <Popconfirm
            title={`удалить?`}
            onConfirm={del}
            okText="да"
            cancelText="нет"
          >
            <Button
              size="small"
              type="primary"
              danger
              loading={loader}
              icon={<DeleteFilled />}
            />
          </Popconfirm>
        </Flex>

        <Modal title="редактирование" open={isModalEdit} onCancel={toClose}>
          {!isCarPage && (
            <EditObject
              getData={getData}
              toClose={toClose}
              isData={isData}
              key={modalKey}
            />
          )}

          {isCarPage && (
            <EditCar
              getData={getData}
              toClose={toClose}
              isData={isData}
              key={modalKey}
            />
          )}
        </Modal>

        <Modal title="создание детали" open={isModal} onCancel={toClose}>
          {isCarPage && (
            <CreateCarDetail
              getData={getData}
              toClose={toClose}
              key={modalKey}
            />
          )}

          {!isCarPage && (
            <CreateObjectDetail
              getData={getData}
              toClose={toClose}
              key={modalKey}
            />
          )}
        </Modal>
      </Flex>
    );
  },
);
