import React from "react";

import { Link } from "react-router-dom";
import "./index.css";

export default function Logo() {
  return (
    <Link to="/" className="logo" key="logo">
      <span className="logo__big">resale</span>

      <span className="logo__small">crm</span>
    </Link>
  );
}
