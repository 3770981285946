import React, { useState, useMemo } from "react";
import { Flex, Descriptions, Button, Popconfirm, Collapse, Modal } from "antd";
import { DeleteFilled, EditFilled } from "@ant-design/icons";
import { myFetch } from "./../../helpers/my_fetch";
import {
  getArrForDescriptionsCarPageRow,
  getArrForDescriptionsObjectPageRow,
} from "./../../helpers/descriptions";
import { showSuccessNotify, showErrorNotify } from "./../../helpers/notify";
import { IDetail } from "./../../types/base_object";
import { UploadImages } from "./../../components/UploadImages";
import EditCarDetail from "./../../components/Forms/Car/EditCarDetail";
import EditObjectDetail from "./../../components/Forms/Object/EditObjectDetail";
import MoveControls from "./../../components/MoveControls";
import DownloadBtn from "./../../components/Controls/DownloadBtn";
// Интерфейс входных данных
export interface IRow {
  getData: Function;
  isData: IDetail;
  id?: String;
  isPage: String;
  index: number;
  length: number;
}
/*
 * Экспорт компонента
 */
export default function TheRow({
  getData,
  isData,
  id,
  isPage,
  index,
  length,
}: IRow) {
  // Состояние видимости компонента Loader
  const [loader, setLoader] = useState(false);
  // Состояние видимости модального окна
  const [isModal, setIsModal] = useState(false);
  // Состояние key модального окна
  const [modalKey, setModalKey] = useState(Date.now());
  // Флаг, является ли это страница машины
  const isCarPage = useMemo(() => isPage === "car", [isPage]);
  // Функция удаления
  const del = async () => {
    // Меняем флаг видимости модального окна
    setLoader(true);
    // Основная логика
    try {
      // Метод удаления объекта
      const { data, error } = await myFetch(
        `${isCarPage ? "car" : "object"}/${id}/${isData.id}`,
        {},
        "DELETE",
      );
      // Показываем notify ошибки
      if (error) return showErrorNotify(error);
      // Показываем notify
      showSuccessNotify(data);
      // Ссылка на функцию запроса данных
      await getData();
    } catch ({ message }: any) {
      // console.log(message)
    } finally {
      // Меняем флаг видимости модального окна
      setLoader(false);
    }
  };
  // Функция закрытия модального окна
  const toClose = async () => {
    // Меняем флаг видимости модального окна
    setIsModal(false);
    // Обновляем key модального окна (для очистки данных в модальном окне)
    setModalKey(Date.now());
  };
  // Возвращаем шаблон компонента
  return (
    <Collapse
      items={[
        {
          key: 1,
          label: isData?.name,
          children: (
            <Flex vertical gap="small">
              <Flex gap="small" justify="space-between">
                <Flex gap="small">
                  <Button
                    size="small"
                    type="primary"
                    onClick={() => setIsModal(true)}
                    icon={<EditFilled />}
                  />

                  <DownloadBtn
                    title="скачать данные объекта"
                    objectName={isData.name}
                    objectId={isData.id}
                  />

                  <Popconfirm
                    title={`удалить?`}
                    onConfirm={del}
                    okText="да"
                    cancelText="нет"
                  >
                    <Button
                      size="small"
                      type="primary"
                      danger
                      icon={<DeleteFilled />}
                      loading={loader}
                    />
                  </Popconfirm>
                </Flex>

                <MoveControls
                  getData={getData}
                  parentId={id}
                  objectId={isData?.id}
                  pageName={isPage}
                  currentIndex={index}
                  length={length}
                />
              </Flex>

              <Collapse
                items={[
                  {
                    key: 1,
                    label: "данные",
                    children: (
                      <Descriptions
                        size="small"
                        items={
                          isCarPage
                            ? getArrForDescriptionsCarPageRow(isData)
                            : getArrForDescriptionsObjectPageRow(isData)
                        }
                      />
                    ),
                  },
                ]}
              />

              <Collapse
                items={[
                  {
                    key: 1,
                    label: "файлы",
                    children: (
                      <UploadImages
                        files={isData?.files}
                        itemid={isData?.id ? String(isData?.id) : ""}
                        getData={getData}
                        single={false}
                      />
                    ),
                  },
                ]}
              />

              <Modal
                title="редактирование детали"
                open={isModal}
                onCancel={toClose}
              >
                {isCarPage && (
                  <EditCarDetail
                    getData={getData}
                    toClose={toClose}
                    isData={isData}
                    key={modalKey}
                  />
                )}

                {!isCarPage && (
                  <EditObjectDetail
                    getData={getData}
                    toClose={toClose}
                    isData={isData}
                    key={modalKey}
                  />
                )}
              </Modal>
            </Flex>
          ),
        },
      ]}
    />
  );
}
