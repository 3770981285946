import React, { useState, useEffect, useMemo } from "react";

import { Input, Button, Flex, Form, Select, Divider } from "antd";
import {
  getBrands,
  getYears,
  maskCarNumber,
  sanitize,
  getDivideStyle,
  delSpaces,
} from "../../../helpers";
import { myFetch } from "../../../helpers/my_fetch";
import { showSuccessNotify, showErrorNotify } from "../../../helpers/notify";
import { IFormEditObject } from "../../../types/props";

const EditCar = React.memo(({ getData, toClose, isData }: IFormEditObject) => {
  const [brand, setBrand] = useState("");
  const [year, setYear] = useState("");
  const [model, setModel] = useState("");
  const [number, setNumber] = useState("");
  const [loading, setLoading] = useState(false);

  const labels = {
    brand: "марка",
    model: "модель",
    year: "год",
    number: "номер",
  };

  const allClear = () => {
    setBrand("");
    setYear("");
    setModel("");
    setNumber("");

    setLoading(false);
  };
  // Действия при изменении isData
  useEffect(() => {
    setBrand(String(isData?.brand));
    setYear(String(isData?.year));
    setModel(String(isData?.model));
    setNumber(String(isData?.number));
  }, [isData]);

  const isDisabled = useMemo(() => {
    return !brand || !year || !model || number.length < 8;
  }, [brand, year, model, number]);

  const isSave = async () => {
    setLoading(true);

    try {
      const { data, error } = await myFetch(
        `car/${isData?.id}`,
        {
          brand: delSpaces(brand),
          model: delSpaces(model),
          year,
          number,
        },
        "PUT",
      );

      if (error) return showErrorNotify(error);

      showSuccessNotify(data);

      await getData();

      toClose(false);
    } catch (e) {
    } finally {
      allClear();
    }
  };

  return (
    <Form style={{ minWidth: "20rem" }} onFinish={isSave}>
      <Flex gap="small" vertical>
        <Flex vertical>
          <Divider orientation="left" style={getDivideStyle()}>
            {labels.brand}
          </Divider>

          <Select
            value={brand}
            placeholder={labels.brand}
            onChange={(val: string) => {
              setBrand(sanitize(val));
            }}
            options={getBrands()}
          />
        </Flex>

        <Flex vertical>
          <Divider orientation="left" style={getDivideStyle()}>
            {labels.model}
          </Divider>

          <Input
            placeholder={labels.model}
            value={model}
            onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => {
              setModel(sanitize(target.value));
            }}
          />
        </Flex>

        <Flex vertical>
          <Divider orientation="left" style={getDivideStyle()}>
            {labels.year}
          </Divider>

          <Select
            value={year}
            placeholder={labels.year}
            onChange={(val: string) => {
              setYear(sanitize(val));
            }}
            options={getYears()}
          />
        </Flex>

        <Flex vertical>
          <Divider orientation="left" style={getDivideStyle()}>
            {labels.number}
          </Divider>

          <Input
            placeholder={labels.number}
            value={number}
            onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => {
              setNumber(maskCarNumber(sanitize(target.value)));
            }}
          />
        </Flex>

        <Button
          size="small"
          type="primary"
          htmlType="submit"
          loading={loading}
          disabled={isDisabled}
        >
          сохранить
        </Button>
      </Flex>
    </Form>
  );
});

export default EditCar;
