import { getCookie } from "./cookie";
import { getLocalStorageData } from "./localstorage";
import { IHeader, IParams } from "../types/my_fetch";
/*
 * Функция создания заголовков для запроса на сервер
 */
export const createHeaders = (isForm: boolean, page: number = 1): IHeader => {
  const h: any = {};
  if (!isForm) h["Content-Type"] = "application/json";
  const idFromLocalstorage = getLocalStorageData("id");
  const idFromCookie = getCookie("id");
  h.datestart = new Date().getTime();
  h.page = page;
  h.userid = idFromCookie || idFromLocalstorage || "auth";
  return h;
};
/*
 * Функция создания параметров для запроса на сервер
 */
export const createParams = (
  method: string,
  body: any,
  headers: any,
): IParams => {
  if (method === "GET") return { method, headers };
  return { method, body, headers };
};
/*
 * Функция запроса на сервер
 */
export const myFetch = async (
  url: any,
  b: any = {},
  m: any = "GET",
  p: any = 1,
  archive: boolean = false,
) => {
  const path = process.env.REACT_APP_BASE_URL || null;
  const h: IHeader = createHeaders(false, p);
  const data: string = JSON.stringify(b);
  const params: object = createParams(m, data, h);
  return archive
    ? await fetch(`${path}/${url}`, params)
    : (await fetch(`${path}/${url}`, params)).json();
};
/*
 * Функция отправки файлов на сервер
 */
export const uploadFiles = async (
  form: any,
  type: string,
  objectid: string,
  itemid?: string,
  main?: Boolean | undefined,
) => {
  const h: any = {};
  const p: any = createParams("POST", form, h);
  const idLocal = getLocalStorageData("id");
  const idCookie = getCookie("id");
  h.userid = idCookie || idLocal;
  h.type = type;
  h.objectid = objectid;
  h.itemid = itemid;
  h.main = main;
  const path = `${process.env.REACT_APP_BASE_URL}/file/add`;
  return (await fetch(path, p)).json();
};
/*
 * Функция удаления файла на сервере
 */
export const delFile = async (
  type: string, // Тип объекта (например, "objects" или "cars")
  name: string, // Имя файла
  objectid: string, // Идентификатор объекта
  itemid?: string, // Необязательный идентификатор элемента
) => {
  // Получаем ID пользователя из локального хранилища
  const idLocal = getLocalStorageData("id");
  // Получаем ID пользователя из cookie
  const idCookie = getCookie("id");
  // Формируем заголовки запроса с данными пользователя
  const h: any = {
    userid: idCookie || idLocal, // ID пользователя (
    type: type, // Тип объекта
    namefile: name, // Название файла
    objectid: objectid, // Идентификатор объекта
    itemid: itemid, // Идентификатор элемента (если имеется)
  };
  // Создаем параметры запроса с методом "POST" и заголовками
  const p: any = createParams("DELETE", {}, h);
  // URL для запроса к API на удаление файла
  const path = `${process.env.REACT_APP_BASE_URL}/file`;
  // Выполняем запрос к API и возвращаем ответ в формате JSON
  return (await fetch(path, p)).json();
};
/*
 * Функция запроса архива с сервера
 */
export const fetchBD = async (objectId: String | undefined) => {
  const path = process.env.REACT_APP_BASE_URL;
  if (!path) return;
  const headers: any = {};
  headers["Content-Type"] = "application/json";
  const idFromLocalstorage = getLocalStorageData("id");
  const idFromCookie = getCookie("id");
  headers.datestart = new Date().getTime();
  headers.userid = idFromCookie || idFromLocalstorage;
  if (objectId) headers.objectId = objectId;
  const params: object = { method: "GET", headers };
  return await fetch(`${path}/bd`, params);
};
