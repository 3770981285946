import React from "react";

import "./index.css";
import { Spin } from "antd";

export default function Loader() {
  return (
    <div className="loading">
      <Spin tip="Загрузка..." size="large">
        <div className="content" />
      </Spin>
    </div>
  );
}
