import React, { useState, useMemo } from "react";

import { Button, Checkbox, Form, Input, Flex, notification } from "antd";
import Logo from "../../components/Logo";
import { maskPhoneNumber, sanitize } from "../../helpers";
import { myFetch } from "../../helpers/my_fetch";
import {
  setLocalStorageData,
  getStatusLocalStorageData,
} from "../../helpers/localstorage";
import { setCookie, getStatusCookie } from "../../helpers/cookie";
import { Navigate } from "react-router-dom";
import { IRes } from "../../types/index";

export default function LoginPage() {
  const [phone, setPhone] = useState("");
  const [loadingAuth, setLoadingAuth] = useState(false);
  const [loadingRegister, setLoadingRegister] = useState(false);
  const [isAuthForm, setIsAuthForm] = useState(true);
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [remember, setRemember] = useState(false);
  const [isAuth, setAuth] = useState(false);

  const statusLocalstorage = getStatusLocalStorageData();
  const statusCookie = getStatusCookie();

  const maskPhone = "8 (###) ###-##-##";
  const minLengthPass = 8;
  const lengthPhone = 17;

  const isDisabled = useMemo(() => {
    if (isAuthForm)
      return (
        !Boolean(password.length >= minLengthPass) ||
        !Boolean(phone.length === lengthPhone)
      );
    else
      return (
        !Boolean(repeatPassword === password) ||
        !Boolean(password.length >= minLengthPass) ||
        !Boolean(phone.length)
      );
  }, [isAuthForm, phone, password, repeatPassword]);

  const changePassword = ({ target }: any): void => {
    setPassword(sanitize(target.value));
  };

  const changeRepeatPassword = ({ target }: any): void => {
    setRepeatPassword(sanitize(target.value));
  };

  const changeRemember = ({ target }: any): void => {
    setRemember(target.checked);
  };

  const clearInputs = (): void => {
    setPhone("");
    setPassword("");
    setRepeatPassword("");
  };

  const changeIsForm = (): void => {
    clearInputs();
    setIsAuthForm(!isAuthForm);
  };

  const showErrNotify = (err: string) => {
    notification.error({
      message: "ошибка ",
      description: err,
    });
  };

  const auth = async () => {
    if (isDisabled) return;

    setLoadingAuth(true);
    // Основная логика
    try {
      const body = { phone: phone.replace(/[ )(-]/g, ""), password };

      const { data, error }: IRes = await myFetch("user/auth", body, "POST");

      if (error) return showErrNotify(error);

      const accessKeys = ["_id", "name"];

      if (remember) {
        for (const key of accessKeys) {
          setLocalStorageData(key === "_id" ? "id" : key, data[key]);
        }
      } else {
        for (const key of accessKeys) {
          setCookie(key === "_id" ? "id" : key, data[key]);
        }
      }

      setAuth(true);
    } catch (e) {
      showErrNotify("ошибка авторизации");
    } finally {
      setLoadingAuth(false);
      clearInputs();
    }
  };

  const register = async () => {
    if (isDisabled) return;

    setLoadingRegister(true);

    try {
      const body = { phone: phone.replace(/[ )(-]/g, ""), password };

      const { error } = await myFetch("user/register", body, "POST");

      if (error) return showErrNotify(error);

      notification.success({
        message: "успешная регистрация",
      });

      setIsAuthForm(true);
    } catch (e) {
      showErrNotify("ошибка регистрации");
    } finally {
      setLoadingRegister(false);
      clearInputs();
    }
  };

  return isAuth || statusLocalstorage || statusCookie ? (
    <Navigate to="/" />
  ) : (
    <Flex
      justify="center"
      align="center"
      style={{ height: "100vh", width: "100vw" }}
    >
      <Form
        style={{ minWidth: "20rem" }}
        onFinish={isAuthForm ? auth : register}
      >
        <Flex vertical gap="small">
          <Flex justify="center">
            <Logo />
          </Flex>

          <Flex vertical gap="small">
            <label htmlFor="inpPhone">номер</label>

            <Input
              id="inpPhone"
              value={phone}
              onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => {
                setPhone(maskPhoneNumber(sanitize(target.value)));
              }}
              placeholder={maskPhone}
              allowClear
            />
          </Flex>

          <Flex vertical gap="small">
            <label htmlFor="inpPass">
              {isAuthForm ? "пароль" : "пароль (минимум 8 символов)"}
            </label>

            <Input.Password
              id="inpPass"
              value={password}
              onChange={changePassword}
            />
          </Flex>

          {!isAuthForm && (
            <Flex vertical gap="small">
              <label htmlFor="inpRepeatPass">повторите пароль</label>

              <Input.Password
                id="inpRepeatPass"
                value={repeatPassword}
                onChange={changeRepeatPassword}
              />
            </Flex>
          )}

          <Flex vertical gap="small">
            {isAuthForm && (
              <Checkbox checked={remember} onChange={changeRemember}>
                оставаться в системе
              </Checkbox>
            )}

            <Button
              type="primary"
              htmlType="submit"
              loading={isAuthForm ? loadingAuth : loadingRegister}
              disabled={isDisabled}
            >
              {isAuthForm ? "авторизоваться" : "зарегистрироваться"}
            </Button>
          </Flex>

          <Flex justify="end">
            <Button type="link" onClick={changeIsForm}>
              {isAuthForm ? "регистрация" : "авторизация"}
            </Button>
          </Flex>
        </Flex>
      </Form>
    </Flex>
  );
}
