// Импорт React и useState
import React, { useState } from "react";
// Импорт компонентов Ant Design
import { Button, Flex, Popconfirm } from "antd";
// Импорт иконок из Ant Design
import { DeleteFilled, DownloadOutlined } from "@ant-design/icons";
import { downloadImage } from "../../helpers";
import { delFile } from "../../helpers/my_fetch";
import { showSuccessNotify, showErrorNotify } from "../../helpers/notify";
// Импортируем интерфейс файла
import { IFile } from "../../types/base_object";
// Интерфейс входных данных
interface IProps {
  currentType: string;
  id: string | undefined;
  itemid: string | undefined;
  getData: Function;
  file: IFile;
}
// Компонент кнопок [скачать, удалить]
export default function Controls(props: IProps) {
  const { currentType, id } = props;
  const { itemid, getData, file } = props;
  // Инициализируем состояние загрузки при удалении
  const [delLoading, setDelLoading] = useState<boolean>(false);
  // Инициализируем состояние загрузки при удалении
  const [downloadLoading, setDownloadLoading] = useState<boolean>(false);
  // Функция отправки запроса на удаление файла
  const isDelete = async () => {
    // Устанавливаем состояние загрузки удаления
    setDelLoading(true);
    // Основная логика
    try {
      // Отправляем запрос на удаление файла
      const { data, error } = await delFile(
        currentType,
        file?.name?.new,
        id || "",
        itemid || "",
      );
      // Если есть ошибка, показываем уведомление об ошибке
      if (error) return showErrorNotify(error);
      // Показываем уведомление об успешном удалении
      showSuccessNotify(data);
      // Функция получения данных
      await getData();
    } catch (e) {
      // Обработка ошибок
    } finally {
      // Сбрасываем состояние загрузки удаления
      setDelLoading(false);
    }
  };
  const isDownloading = () => {
    // Устанавливаем состояние скачивания
    setDownloadLoading(true);
    // Основная логика
    try {
      // Метод скачивания файла с сервера
      downloadImage(file?.name?.new);
    } catch (err) {
    } finally {
      // Сбрасываем состояние скачивания
      setDownloadLoading(false);
    }
  };
  // Возвращаем шаблон компонента
  return (
    <Flex gap="small">
      <Popconfirm
        title="удалить?"
        onConfirm={() => isDelete()}
        okText="да"
        cancelText="нет"
      >
        <Button
          key={`${file?.name?.new}-del`}
          size="small"
          type="primary"
          danger
          loading={delLoading}
          icon={<DeleteFilled />}
          style={{ width: "100%" }}
        />
      </Popconfirm>

      <Button
        key={`${file?.name?.new}-del`}
        size="small"
        type="primary"
        onClick={() => isDownloading()}
        loading={downloadLoading}
        icon={<DownloadOutlined />}
        style={{ width: "100%" }}
      />
    </Flex>
  );
}
