import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { Flex, Empty, Divider } from "antd";
import Loader from "../../components/Loader";
import InsidePageHeader from "../../components/Header/InsidePage";
import TheRow from "../../components/Row";
import { useBreadcrumbsContext } from "../../components/Breadcrumbs/context";
import { myFetch } from "../../helpers/my_fetch";
import { InsidePageBtns } from "../../components/Controls/InsidePage/buttons";
import { showErrorNotify } from "../../helpers/notify";
import { IRes } from "../../types/index";
// Экспорт компонента страницы объекта
export default function ObjectPage() {
  // Состояние объекта
  const [object, setObject] = useState({} as any);
  // Состояние Loader
  const [loader, setLoader] = useState(true);
  // Hook хлебных крошек
  const { addBreadcrumb, removeBreadcrumb } = useBreadcrumbsContext();
  // Берем ID из URL строки
  const { id } = useParams();
  // Функция запроса данных объекта, если ID изменился
  const getData = useCallback(async () => {
    try {
      // Получаем данные объекта
      const { data, error }: IRes = await myFetch(`object/${id}`, {}, "GET");
      // Если в ответе ошибка, показываем notify с текстом
      if (error) return showErrorNotify(error);
      // Добавляем данные объекта
      setObject(data);
      // Возвращаем данные объекта
      return data;
    } catch ({ message }: any) {
      // console.log(message)
    } finally {
      // Скрываем Loader
      setLoader(false);
    }
  }, [id]);
  // Действия при инициализации компонента
  useEffect(() => {
    // Флаг монтирования компонента
    let isMounted = true;
    // Функция добавления хлебных крошек
    const setCrumb = async () => {
      // Получение данных
      const res: any = await getData();
      // Если сейчас происходит монтирование и молучены данные
      if (isMounted && res) {
        const crumb = {
          name: res?.name || "",
          path: "/objects",
        };
        // Добавляем хлебные крошки
        addBreadcrumb(crumb);
      }
    };
    // Добавляем хлебные крошки
    setCrumb();
    // Возвращаем логику
    return () => {
      if (isMounted) {
        // Удаляем элемент из хлебных крошек
        removeBreadcrumb("/objects");
      }
      // Флаг монтирования компонента
      isMounted = false;
    };
  }, [addBreadcrumb, removeBreadcrumb, getData]);
  // Показываем Loader
  if (loader) return <Loader />;
  // Возвращаем шаблон компонента
  return (
    <Flex gap="small" vertical>
      <InsidePageBtns
        getData={getData}
        id={String(id)}
        isData={object}
        isPage="object"
      />

      <InsidePageHeader getData={getData} isData={object} id={String(id)} />

      <Divider style={{ margin: 0 }}>детали</Divider>

      {!object?.details && <Empty />}

      {object?.details &&
        object?.details?.map((detail: any, i: number) => (
          <TheRow
            getData={getData}
            id={String(id)}
            isData={detail}
            isPage="object"
            index={i}
            length={object?.details?.length}
            key={detail?.id}
          />
        ))}
    </Flex>
  );
}
