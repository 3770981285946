import React from "react";
import { useNavigate } from "react-router-dom";
import { Flex } from "antd";
import { generateImagePath } from "../../helpers";
import { ICard } from "../../types/props";
import "./index.css";
// Создаем мемоизированный компонент карточки объекта/машины
const TheCard = React.memo(({ object, isGrid, isPage }: ICard) => {
  const navigate = useNavigate();
  // Возвращаем шаблон
  return (
    <div className="card">
      <Flex
        gap="small"
        vertical
        onClick={() => navigate(`/${isPage}s/${object?.id}`)}
      >
        {isGrid ? (
          <img
            style={{ height: "112px", width: "100%" }}
            src={generateImagePath(object.image?.name?.new)}
            loading="lazy"
            className="card__img"
            alt=""
          />
        ) : (
          <img
            style={{ height: "5rem", width: "5rem" }}
            src={generateImagePath(object.image?.name?.new)}
            loading="lazy"
            className="card__img"
            alt=""
          />
        )}

        <p className="card__text">
          {object?.brand || object?.name || "неизвестно"}
        </p>
      </Flex>
    </div>
  );
});

export default TheCard;
