import React from "react";

import { Navigate, Outlet } from "react-router-dom";
import { getStatusLocalStorageData } from "../../helpers/localstorage";
import { getStatusCookie } from "../../helpers/cookie";

export default function Middleware() {
  const statusLocalstorage = getStatusLocalStorageData();
  const statusCookie = getStatusCookie();

  if (statusLocalstorage || statusCookie) {
    return <Outlet />;
  } else {
    return <Navigate to="/login" />;
  }
}
