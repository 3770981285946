import React from "react";

import { Link, useLocation } from "react-router-dom";
import { Flex } from "antd";
import { useBreadcrumbsContext } from "./context";
import { list } from "../../data/breadcrumbs";
import "./index.css";

export default function Breadcrumbs() {
  const { breadcrumbs: dynamicCrumbs } = useBreadcrumbsContext();
  const location = useLocation();

  let currentPath = "";
  const allCrumbs = [...list, ...dynamicCrumbs];
  const filteredCrumbs = allCrumbs.filter((crumb) => {
    currentPath = currentPath ? `${currentPath}/${crumb.path}` : crumb.path;
    return location.pathname.includes(crumb.path);
  });

  const isLastCrumb = (index: number) => index === filteredCrumbs.length - 1;

  const crumbs = filteredCrumbs.map(({ name, path }, index) => {
    const isLast = isLastCrumb(index);
    return isLast ? (
      <span className="crumb" key={`${path}-${index}`}>
        {name}
      </span>
    ) : (
      <Link to={path} className="crumb" key={`${path}-${index}`}>
        {name}
      </Link>
    );
  });

  return <Flex align="center">{crumbs}</Flex>;
}
