import React, { useState, useEffect, useMemo } from "react";

import { Input, Button, Flex, Form, Divider } from "antd";

import { sanitize, getDivideStyle } from "../../../helpers";
import { myFetch } from "../../../helpers/my_fetch";
import { showSuccessNotify, showErrorNotify } from "../../../helpers/notify";
import { IFormEditObject } from "../../../types/props";

const EditObject = React.memo(
  ({ getData, toClose, isData }: IFormEditObject) => {
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [loading, setLoading] = useState(false);

    const labels = {
      name: "название",
      description: "описание",
    };

    const allClear = () => {
      setName("");
      setDescription("");
      setLoading(false);
    };

    const isDisabled = useMemo(() => {
      return !name;
    }, [name]);
    // Действия при инициализации компонента
    useEffect(() => {
      allClear();
    }, []);

    useEffect(() => {
      setName(String(isData?.name));
      setDescription(
        isData?.description === "неизвестно" ? "" : String(isData?.description),
      );
    }, [isData]);

    const isSave = async () => {
      setLoading(true);

      try {
        const { data, error } = await myFetch(
          `object/${isData?.id}`,
          {
            name: name,
            description: description,
          },
          "PUT",
        );

        if (error) return showErrorNotify(error);

        showSuccessNotify(data);

        await getData();

        toClose(false);
      } catch (e) {
      } finally {
        allClear();
      }
    };

    return (
      <Form style={{ minWidth: "20rem" }} onFinish={isSave}>
        <Flex gap="small" vertical>
          <Flex vertical>
            <Divider orientation="left" style={getDivideStyle()}>
              {labels.name}
            </Divider>

            <Input
              placeholder={labels.name}
              value={name}
              onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => {
                setName(sanitize(target.value));
              }}
            />
          </Flex>

          <Flex vertical>
            <Divider orientation="left" style={getDivideStyle()}>
              {labels.description}
            </Divider>

            <Input.TextArea
              placeholder={labels.description}
              value={description}
              onChange={({
                target,
              }: React.ChangeEvent<HTMLTextAreaElement>) => {
                setDescription(sanitize(target.value));
              }}
              rows={2}
            />
          </Flex>

          <Button
            size="small"
            type="primary"
            htmlType="submit"
            loading={loading}
            disabled={isDisabled}
          >
            сохранить
          </Button>
        </Flex>
      </Form>
    );
  },
);

export default EditObject;
