import React, { useState, useEffect } from "react";

import { Input, Button, Flex } from "antd";

import { MinusOutlined } from "@ant-design/icons";

import { maskCost, sanitize } from "../../../helpers";
import { IOption } from "../../../types";

const Field = ({
  fieldName,
  onFieldChange,
  onDeleteField,
  value,
}: {
  fieldName: string;
  onFieldChange: Function;
  onDeleteField: Function;
  value: IOption;
}) => {
  const [name, setName] = useState("");
  const [cost, setCost] = useState("");

  useEffect(() => {
    if (value.name) setName(value.name);

    if (value.cost) setCost(value.cost);
  }, [value]);

  useEffect(() => {
    onFieldChange(fieldName, { name: name, cost: cost });
  }, [name, cost]);

  return (
    <Flex gap="small" align="center">
      <Input
        placeholder="название"
        value={name}
        onChange={({ target }) => setName(sanitize(target.value))}
      />

      <Input
        placeholder="стоимость"
        value={cost}
        onChange={({ target }: React.ChangeEvent<HTMLInputElement>) =>
          setCost(maskCost(sanitize(target.value)))
        }
      />

      <Button
        size="small"
        type="primary"
        danger
        icon={<MinusOutlined />}
        style={{
          minWidth: "1.9375rem",
          minHeight: "1.9375rem",
          borderRadius: "0.375rem",
        }}
        onClick={() => onDeleteField(fieldName)}
      />
    </Flex>
  );
};

export default Field;
