import React, { useState } from "react";
import { LayoutFormat } from "../LayoutFormat";
import { MyModal } from "../MyModal";
import "./index.css";
// Интерфейс входных данных
interface IProps {
  src: string; // Путь к изображению
  alt: string; // Альтенрнативное название
  single?: Boolean;
}
// Компонент изображения файла
export const ImageViewer = ({ src, alt = "", single = false }: IProps) => {
  // Состояние видимости модального окна
  const [show, changeShow] = useState<boolean>(false);
  // Возвращаем шаблон
  return (
    <>
      <LayoutFormat label="webp" single={single}>
        <img
          className="image-viewer__img"
          style={{ cursor: single ? "default" : "pointer" }}
          src={src}
          alt={alt}
          onClick={() => changeShow(single ? false : true)}
        />
      </LayoutFormat>

      {show && (
        <MyModal onCancel={() => changeShow(false)}>
          <img className="image-viewer__img_big" src={src} alt={alt} />
        </MyModal>
      )}
    </>
  );
};
