import React, { createContext, useState, useContext, useCallback } from "react";

import { ICrumb, IContext } from "../../types/breadcrumbs";

const BreadcrumbsContext = createContext<IContext | undefined>(undefined);

export const useBreadcrumbsContext = () => {
  const context = useContext(BreadcrumbsContext);
  if (context === undefined) {
    throw new Error(
      "useBreadcrumbsContext must be used within a BreadcrumbsProvider",
    );
  }
  return context;
};

export const BreadcrumbsProvider = React.memo(
  ({ children }: { children: any }) => {
    const [breadcrumbs, setBreadcrumbs] = useState<ICrumb[]>([]);

    const addBreadcrumb = useCallback((crumb: ICrumb) => {
      setBreadcrumbs((prev) => [...prev, crumb]);
    }, []);

    const removeBreadcrumb = useCallback((path: string) => {
      setBreadcrumbs((prev) => prev.filter((crumb) => crumb.path !== path));
    }, []);

    const contextValue = {
      breadcrumbs,
      addBreadcrumb,
      removeBreadcrumb,
    };

    return (
      <BreadcrumbsContext.Provider value={contextValue}>
        {children}
      </BreadcrumbsContext.Provider>
    );
  },
);
