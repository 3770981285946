import React, { useState, useEffect, useMemo } from "react";

import { Input, Button, Flex, Form, Divider } from "antd";

import { PlusOutlined } from "@ant-design/icons";

import { useParams } from "react-router-dom";

import Field from "../components/Field";

import { sanitize, getDivideStyle, maskCost } from "../../../helpers";
import { myFetch } from "../../../helpers/my_fetch";
import { showSuccessNotify, showErrorNotify } from "../../../helpers/notify";
import { IFormEditDetail } from "../../../types/props";
import { IOption } from "../../../types";

const EditObjectDetail = ({ toClose, isData, getData }: IFormEditDetail) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState({});

  const { id } = useParams();

  const labels = {
    name: "название",
    description: "описание",
    cost: "стоимость",
  };

  const allClear = () => {
    setName("");
    setDescription("");
    setOptions({});
    setLoading(false);
  };

  const isDisabled = useMemo(() => {
    return !name;
  }, [name]);

  const addField = (fieldName: string) => {
    setOptions((prevFields) => ({
      ...prevFields,
      [fieldName]: "",
    }));
  };

  const changeField = (name: string, value: Object) => {
    setOptions((prevFields) => ({
      ...prevFields,
      [name]: value,
    }));
  };

  const deleteField = (nameToDelete: string) => {
    setOptions((prev) => {
      const updatedOptions: { [key: string]: IOption } = { ...prev };
      delete updatedOptions[nameToDelete];

      return updatedOptions;
    });
  };
  // Действия при инициализации компонента
  useEffect(() => {
    allClear();

    for (let key of Object.keys(isData.options)) {
      setOptions((prevFields) => ({
        ...prevFields,
        [key]: {
          //@ts-ignore
          name: sanitize(isData.options[key].name),
          //@ts-ignore
          cost: maskCost(isData.options[key].cost),
        },
      }));
    }
  }, []);

  useEffect(() => {
    setName(String(isData?.name));
    setDescription(String(isData?.description));
  }, [isData]);

  const isSave = async () => {
    setLoading(true);

    try {
      const { data, error } = await myFetch(
        `object/detail/${isData.id}`,
        {
          name,
          description,
          options,
          objectid: id,
        },
        "PUT",
      );

      if (error) return showErrorNotify(error);

      showSuccessNotify(data);

      await getData();

      toClose(false);
    } catch (e) {
    } finally {
      allClear();
    }
  };

  return (
    <Form style={{ minWidth: "20rem" }} onFinish={isSave}>
      <Flex gap="small" vertical>
        <Flex vertical>
          <Divider orientation="left" style={getDivideStyle()}>
            {labels.name}
          </Divider>

          <Input
            placeholder={labels.name}
            value={name}
            onChange={({ target }) => setName(sanitize(target.value))}
          />
        </Flex>

        <Flex vertical>
          <Divider orientation="left" style={getDivideStyle()}>
            {labels.description}
          </Divider>

          <Input.TextArea
            placeholder={labels.description}
            value={description}
            onChange={({ target }) => setDescription(sanitize(target.value))}
            rows={2}
          />
        </Flex>

        {Object.keys(options).map((fieldName) => (
          <Field
            key={fieldName}
            fieldName={fieldName}
            onFieldChange={changeField}
            onDeleteField={deleteField}
            //@ts-ignore
            value={options[fieldName]}
          />
        ))}

        <Button
          size="small"
          type="primary"
          style={{ width: "100%", backgroundColor: "var(--green)" }}
          onClick={() => addField(`${Date.now()}`)}
          icon={<PlusOutlined />}
        >
          поле
        </Button>

        <Button
          size="small"
          type="primary"
          htmlType="submit"
          loading={loading}
          disabled={isDisabled}
        >
          сохранить
        </Button>
      </Flex>
    </Form>
  );
};

export default EditObjectDetail;
