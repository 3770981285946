import React, { useState, useEffect, useMemo } from "react";

import { Input, Button, Flex, Form, Divider } from "antd";

import { PlusOutlined } from "@ant-design/icons";

import { useParams } from "react-router-dom";

import Field from "../components/Field";

import { maskCost, sanitize, getDivideStyle } from "../../../helpers";
import { myFetch } from "../../../helpers/my_fetch";
import { showSuccessNotify, showErrorNotify } from "../../../helpers/notify";
import { IFormCreate } from "../../../types/props";
import { IOption } from "../../../types";

const CreateCarDetail = React.memo(({ getData, toClose }: IFormCreate) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [milleage, setMilleage] = useState("");
  const [options, setOptions] = useState({});
  const [loading, setLoading] = useState(false);

  const { id } = useParams();

  const labels = {
    name: "название",
    description: "описание",
    mileage: "пробег",
  };

  const allClear = () => {
    setName("");
    setDescription("");
    setMilleage("");
    setOptions({});
    setLoading(false);
  };
  // Действия при инициализации компонента
  useEffect(() => {
    allClear();
  }, []);

  const addField = (fieldName: string) => {
    setOptions((prevFields) => ({
      ...prevFields,
      [fieldName]: "",
    }));
  };

  const changeField = (name: string, value: Object) => {
    setOptions((prevFields) => ({
      ...prevFields,
      [name]: value,
    }));
  };

  const deleteField = (nameToDelete: string) => {
    setOptions((prev) => {
      const updatedOptions: { [key: string]: IOption } = { ...prev };
      delete updatedOptions[nameToDelete];

      return updatedOptions;
    });
  };

  const isCreate = async () => {
    setLoading(true);

    try {
      const { data, error } = await myFetch(
        `car/detail/${id}`,
        {
          name,
          description,
          milleage,
          options,
        },
        "POST",
      );

      if (error) return showErrorNotify(error);

      showSuccessNotify(data);

      await getData();

      toClose(false);
    } catch (e) {
    } finally {
      allClear();
    }
  };

  const isDisabled = useMemo(() => {
    return !name;
  }, [name]);

  return (
    <Form style={{ minWidth: "20rem" }} onFinish={isCreate}>
      <Flex gap="small" vertical>
        <Flex vertical>
          <Divider orientation="left" style={getDivideStyle()}>
            {labels.name}
          </Divider>

          <Input
            placeholder={labels.name}
            value={name}
            onChange={({ target }: React.ChangeEvent<HTMLInputElement>) =>
              setName(sanitize(target.value))
            }
          />
        </Flex>

        <Flex vertical>
          <Divider orientation="left" style={getDivideStyle()}>
            {labels.description}
          </Divider>

          <Input.TextArea
            placeholder={labels.description}
            value={description}
            onChange={({ target }: React.ChangeEvent<HTMLTextAreaElement>) =>
              setDescription(sanitize(target.value))
            }
            rows={2}
          />
        </Flex>

        <Flex vertical>
          <Divider orientation="left" style={getDivideStyle()}>
            {labels.mileage}
          </Divider>

          <Input
            placeholder={labels.mileage}
            value={milleage}
            onChange={({ target }: React.ChangeEvent<HTMLInputElement>) =>
              setMilleage(maskCost(sanitize(target.value)))
            }
          />
        </Flex>

        {Object.keys(options).map((fieldName) => (
          <Field
            key={fieldName}
            fieldName={fieldName}
            onFieldChange={changeField}
            onDeleteField={deleteField}
            //@ts-ignore
            value={options[fieldName]}
          />
        ))}

        <Button
          size="small"
          type="primary"
          style={{ width: "100%", backgroundColor: "var(--green)" }}
          onClick={() => addField(`${Date.now()}`)}
          icon={<PlusOutlined />}
        >
          поле
        </Button>

        <Button
          size="small"
          type="primary"
          htmlType="submit"
          loading={loading}
          disabled={isDisabled}
        >
          создать
        </Button>
      </Flex>
    </Form>
  );
});

export default CreateCarDetail;
