import React from "react";

import "./index.css";
import { BreadcrumbsProvider } from "../Breadcrumbs/context";
import { Outlet } from "react-router-dom";
import { Flex } from "antd";
import HeaderMain from "../Header/Main";
import Breadcrumbs from "../Breadcrumbs";

export default function Layout() {
  return (
    <BreadcrumbsProvider>
      <Flex className="layout" gap="small" vertical>
        <HeaderMain />

        <main className="container">
          <Flex vertical gap="small">
            <Breadcrumbs />

            <Outlet />
          </Flex>
        </main>
      </Flex>
    </BreadcrumbsProvider>
  );
}
