import React, { useState, useEffect, useMemo } from "react";

import { Input, Button, Flex, Form, Divider } from "antd";

import { PlusOutlined } from "@ant-design/icons";

import { useParams } from "react-router-dom";

import { sanitize, getDivideStyle } from "../../../helpers";
import { myFetch } from "../../../helpers/my_fetch";
import { showSuccessNotify, showErrorNotify } from "../../../helpers/notify";
import { IFormCreate } from "../../../types/props";
import { IOption } from "../../../types";

import Field from "../components/Field";

const CreateObjectDetail = ({ getData, toClose }: IFormCreate) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState({});

  const { id } = useParams();

  const allClear = () => {
    setName("");
    setOptions({});
    setLoading(false);
  };

  const addField = (fieldName: string) => {
    setOptions((prevFields) => ({
      ...prevFields,
      [fieldName]: "",
    }));
  };

  const changeField = (name: string, value: Object) => {
    setOptions((prevFields) => ({
      ...prevFields,
      [name]: value,
    }));
  };

  const deleteField = (nameToDelete: string) => {
    setOptions((prev) => {
      const updatedOptions: { [key: string]: IOption } = { ...prev };
      delete updatedOptions[nameToDelete];

      return updatedOptions;
    });
  };

  const isDisabled = useMemo(() => {
    return !name;
  }, [name]);
  // Действия при инициализации компонента
  useEffect(() => {
    allClear();
  }, []);

  const isCreate = async () => {
    setLoading(true);

    try {
      const object = {
        name: name,
        description: description,
        options,
        objectid: id,
      };

      const { data, error } = await myFetch("object/detail", object, "POST");

      if (error) return showErrorNotify(error);

      showSuccessNotify(data);

      await getData();

      toClose(false);
    } catch (e) {
    } finally {
      allClear();
    }
  };

  return (
    <Form style={{ minWidth: "20rem" }} onFinish={isCreate}>
      <Flex gap="small" vertical>
        <Flex vertical>
          <Divider orientation="left" style={getDivideStyle()}>
            {"название"}
          </Divider>

          <Input
            placeholder={"название"}
            value={name}
            onChange={({ target }) => setName(sanitize(target.value))}
          />
        </Flex>

        <Flex vertical>
          <Divider orientation="left" style={getDivideStyle()}>
            {"описание"}
          </Divider>

          <Input.TextArea
            placeholder="описание"
            value={description}
            onChange={({ target }) => setDescription(sanitize(target.value))}
            rows={2}
          />
        </Flex>

        {Object.keys(options).map((fieldName) => (
          <Field
            key={fieldName}
            fieldName={fieldName}
            onFieldChange={changeField}
            onDeleteField={deleteField}
            //@ts-ignore
            value={options[fieldName]}
          />
        ))}

        <Button
          size="small"
          type="primary"
          style={{ width: "100%", backgroundColor: "var(--green)" }}
          onClick={() => addField(`${Date.now()}`)}
          icon={<PlusOutlined />}
        >
          поле
        </Button>

        <Button
          size="small"
          type="primary"
          htmlType="submit"
          loading={loading}
          disabled={isDisabled}
        >
          создать
        </Button>
      </Flex>
    </Form>
  );
};

export default CreateObjectDetail;
