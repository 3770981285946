import React, { useState, useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  Button,
  Flex,
  Image,
  Popconfirm,
  Descriptions,
  Divider,
  Collapse,
} from "antd";
import {
  DeleteFilled,
  EditFilled,
  EyeInvisibleFilled,
} from "@ant-design/icons";
import { generateImagePath } from "../../../helpers";
import { myFetch } from "../../../helpers/my_fetch";
import {
  getArrForDescriptionsCarPage,
  getArrForDescriptionsObjectPage,
} from "../../../helpers/descriptions";
import { UploadImages } from "../../../components/UploadImages";
import { IHeaderInside } from "../../../types/props";
import { IBreakpoint, IState } from "../../../types/store";
import { ImageViewer } from "../../ImageViewer";
// Экспорт компонента
export default function InsidePageHeader({
  isData,
  getData,
  id,
}: IHeaderInside) {
  // Флаг о том (является ли сейчас ширина экрана, как у Desktop устройств)
  const { desktop }: IBreakpoint = useSelector(
    (state: IState) => state.breakpoints,
  );
  // Текущая ширина экрана устройства
  const { width } = useSelector((state: IState) => state.windowSize);
  // Объект текущего местоположения в приложении
  const location = useLocation();
  // Тип страницы
  const isType = location.pathname.split("/").filter((e) => e);
  // Тип страницы
  const currentType = isType[0];
  // Флаг о том (является ли сейчас ширина экрана, как у Desktop устройств)
  const isDesktop = useMemo(() => width >= desktop, [width, desktop]);
  // Флаг видимости компонента Loader при удалении изображения
  const [loaderDelImage, setLoaderDelImage] = useState(false);
  // Флаг о редактировании изображения
  const [changeImage, setChangeImage] = useState(false);
  const [imagePath, setImagePath] = useState("");
  // Функция удаления изображения объекта/машины
  const delImage = async () => {
    // Изменение флага видимости компонента Loader
    setLoaderDelImage(true);
    // Основная логика
    try {
      // Отправка запроса для удаления изображения на сервер
      await myFetch(
        `${currentType === "objects" ? "object" : "car"}/${id}/file/main`,
        { name: isData?.image?.name?.new },
        "DELETE",
      );
      // Ссылка на функцию запроса данных
      await getData();
    } catch ({ message }: any) {
      // console.log(message)
    } finally {
      // Изменение флага видимости компонента Loader
      setLoaderDelImage(false);
    }
  };
  // Действия при инициализации компонента
  useEffect(() => {
    const filePath = generateImagePath(
      isData?.image?.thumb ? isData?.image?.thumb : isData?.image?.name?.new,
    );
    setImagePath(filePath);
  }, []);
  // Возвращаем шаблон компонента
  return (
    <Flex gap="small" vertical>
      <Flex gap="small" vertical={!isDesktop}>
        {changeImage ? (
          <Flex vertical gap="small">
            <UploadImages
              single={true}
              getData={getData}
              toClose={async () => {
                setChangeImage(false);

                await getData();
              }}
            />

            <Button
              size="small"
              type="primary"
              danger
              onClick={() => setChangeImage(false)}
              icon={<EyeInvisibleFilled />}
              style={{ width: "100%" }}
            />
          </Flex>
        ) : (
          <Flex vertical gap="small">
            <div
              style={{
                height: !isDesktop ? "348px" : "148px",
                width: !isDesktop ? "100%" : "148px",
              }}
            >
              <ImageViewer
                src={imagePath}
                alt={isData?.image?.name?.old}
                single={true}
              />
            </div>

            <Flex gap="small" justify="flex-end">
              <Button
                size="small"
                type="primary"
                onClick={() => setChangeImage(true)}
                icon={<EditFilled />}
              />

              {isData.image && (
                <Popconfirm
                  title={`удалить?`}
                  onConfirm={delImage}
                  okText="да"
                  cancelText="нет"
                >
                  <Button
                    size="small"
                    type="primary"
                    danger
                    loading={loaderDelImage}
                    icon={<DeleteFilled />}
                  />
                </Popconfirm>
              )}
            </Flex>
          </Flex>
        )}

        {!isDesktop && <Divider style={{ margin: 0 }}>информация</Divider>}

        {currentType === "cars" && (
          <Descriptions
            size="small"
            items={getArrForDescriptionsCarPage(isData)}
          />
        )}

        {currentType === "objects" && (
          <Descriptions
            size="small"
            items={getArrForDescriptionsObjectPage(isData)}
          />
        )}
      </Flex>

      <Collapse
        items={[
          {
            key: 1,
            label: "файлы",
            children: (
              <UploadImages
                getData={getData}
                files={isData?.files}
                single={false}
              />
            ),
          },
        ]}
      />
    </Flex>
  );
}
