import React from "react";
import "./index.css"; // Импорт стилей
// Интерфейс входных параметров
interface IProps {
  children: any; // Прокидываемый компонент
  label: string; // Формат файла
  single?: Boolean;
}
// Компонент-обвертка для показа в верхнем левом углу формат файла
export const LayoutFormat = React.memo(
  ({ children, label = "", single = false }: IProps) => {
    // Возвращаем шаблон
    return (
      <div className="layout-format">
        {!single && <span className="layout-format__label">{label}</span>}

        {children}
      </div>
    );
  },
);
