import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useSelector } from "react-redux";
import { myFetch } from "../../helpers/my_fetch";
import { Flex, Button, Modal, Col, Row } from "antd";
import CreateCar from "../../components/Forms/Car/CreateCar";
import TheCard from "../../components/Card";
import Loader from "../../components/Loader";
import { PlusCircleFilled } from "@ant-design/icons";
import { showErrorNotify } from "../../helpers/notify";
import ModeControls from "../../components/Controls";
import { IState } from "../../types/store";
import { IRes } from "../../types/index";
/*
 * Компонент страницы машин
 */
export default function CarsPage() {
  const mode = useSelector((state: IState) => state.view);

  const [list, setList] = useState([]);
  const [loader, setLoader] = useState(true);
  const [isModal, setIsModal] = useState(false);
  const [modalKey, setModalKey] = useState(Date.now());
  // Запрос данных с сервера
  const getData = useCallback(async () => {
    try {
      const { data, error }: IRes = await myFetch("car", {}, "GET", 1);

      if (error) return showErrorNotify(error);

      setList(data);
    } catch (e) {
    } finally {
      setLoader(false);
    }
  }, []);
  // Определяем вид списка карточек
  const isGrid = useMemo(() => mode === "grid", [mode]);
  // Действия при изменении getData
  useEffect(() => {
    (async () => {
      await getData();
    })();
  }, [getData]);
  // Функция закрытия модального окна
  const toClose = useCallback(async () => {
    setIsModal(false);
    setModalKey(Date.now());
  }, []);
  // Показываем loader
  if (loader) return <Loader />;
  // Возвращаем шаблон компонента
  return (
    <Flex vertical gap="small">
      <Flex justify="end" gap="small">
        <ModeControls />

        <Button
          size="small"
          type="primary"
          onClick={() => setIsModal(true)}
          style={{ backgroundColor: "var(--green)" }}
          icon={<PlusCircleFilled />}
        />
      </Flex>

      <Row gutter={[8, 8]}>
        {list &&
          list.map((object: any) => {
            return (
              <Col
                xs={isGrid ? 12 : 24}
                sm={isGrid ? 8 : 24}
                md={isGrid ? 8 : 24}
                lg={isGrid ? 6 : 24}
                xl={isGrid ? 4 : 24}
                xxl={isGrid ? 2 : 24}
                key={object?.id}
              >
                <TheCard object={object} isGrid={isGrid} isPage="car" />
              </Col>
            );
          })}
      </Row>

      <Modal title="создать машину" open={isModal} onCancel={toClose}>
        <CreateCar getData={getData} toClose={toClose} key={modalKey} />
      </Modal>
    </Flex>
  );
}
