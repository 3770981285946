export const list = [
  {
    name: "машины",
    path: "/cars",
  },
  {
    name: "объекты",
    path: "/objects",
  },
];
